<template>
  <name-with-avatar
    :avatar-size="32"
    :items="[customer]"
    :name="name"
    v-if="firm"
  >
    <template v-if="firm && firm.doc_id" #subtitle>
      <span class="font-weight-bold text-uppercase" v-text="firm.doc_type" />
      <span class="font-weight-normal ml-2" v-text="firm.doc_id" />
    </template>
  </name-with-avatar>
</template>
<script lang="ts">
import { CustomerData } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, Vue } from "vue-property-decorator";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";

@Component({
  components: { NameWithAvatar },
})
export default class CustomerPreview extends Vue {
  @Prop(Object) readonly customer!: CustomerData;

  get firm() {
    return this.customer ? this.customer.firm : null;
  }

  get name() {
    return this.firm ? this.firm.dgi_denominacion || this.firm.name : null;
  }
}
</script>
